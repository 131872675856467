<template>
    <div class="m-member">
        <div class="menber-wrap">
            <div class="menber-title">
                <div class="menber-text">{{id==1?'会员等级说明':'免责条款'}}</div>
                <div class="menber-p">
                    <span @click="goTo('/login')">登录</span>
                    <span>|</span>
                    <span @click="goTo('/register')">注册</span>
                </div>
            </div>
            <div class="menber" v-html="id==1?list.level_content:list.mianze">
                新丝路外服是一个专业开展国际经贸交流与服务的平台，由河南海外仓供应链管理服务有限公司、河南阿漫博跨境电商有限公司等发起，在中国国际商会郑州商会、联钢国际、非洲商务联盟、Amanbo立体数字营销平台、海外仓集团、联合国采购中国服务中心等众多境内外企业、商协会的支持下，平台以求实求效、为公向善为服务宗旨，以一带一路、新兴国家及地区为主要市场。

通过整合境内外各种资源，利用跨境电商、海外仓、境外展厅、当地营销团队等分销和服务渠道，以及国际营销活动、商务对接、外贸综合服务等方式，为涉外企业做好国际经贸信息与业务交流的服务，帮助企业和产品进入国际市场、提高竞争力、促进中国与世界的商贸、文化交流。
               
            </div>

        </div>

    </div>
</template>

<script>
    import { getwes } from '../api/index'
    export default {
        data() {
            return {
                id:'',
                list:[],                   
            }
        },
        created() {
            this.id=this.$route.query.id
            this.getwes()
        },
        methods: {
            goTo(url) {
                this.$router.replace({ 
                    path: url ,
                });
            },
            getwes(){
                getwes().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.list=res.data;
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.m-member{
    background: #EFF0F1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .menber-wrap{
        width: 1100px;
        .menber-title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
            .menber-text{font-size: 22px;}
            .menber-p{
                font-size: 18px;color: #FD5F08;cursor: pointer;
                span:nth-child(2){margin: 0 10px;}
            }
        }
        .menber{
            height: 600px;
            // padding: 90px 110px;
            padding: 50px;
            box-sizing: border-box;
            background: #fff;
            color: #666666;
            font-size: 18px;
            line-height: 30px;
        }
    }
}

</style>